<template>
  <div class="data-contain">
    <div class="card-wrapper">
      <div class="monitor-search">
        <div class="monitor-date">
          <Date-picker format="yyyy-MM-dd" type="daterange" placeholder="选择日期" @on-change="handleChangeDate"></Date-picker>
        </div>
        <div class="tab-contain">
          <router-link class="static-button" :to="{name: 'Capacity'}" >统计显示</router-link>
          <router-link :to="{name: 'CapacityTable'}">数据显示</router-link>
          <router-link class="chart-button" :to="{name: 'CapacityChart'}">图表显示</router-link>
        </div>
      </div>
    </div>
    <div class="add-box">
      <Row>
        <Col span="3" offset="19">
          <a href="javascript:;" @click="handleStander" class="stander">肺活量参考标准</a>
        </Col>
        <Col span="2">
          <div class="member-oprate" v-show="power.indexOf('会员管理--编辑会员信息权限') > -1">
            <router-link class="new-doc fr" :to="{name: 'CapacityAdd'}">添加信息</router-link>
          </div>
        </Col>
      </Row>
    </div>

    <div class="table-box">
      <Table :columns="columns" :data="capacityList" ref="table" @on-selection-change="handleSelect"></Table>

      <Row>
        <Col span="12">
          <div class="table-oprate">
            <!-- <Checkbox>全选</Checkbox> -->
            <Button style="background: #fff;" @click="handleAllRemove" v-show="power.indexOf('会员管理--删除会员信息权限') > -1">删除</Button>
          </div>
        </Col>
        <Col span="12">
          <span class="records">共{{pageTotal}}条记录</span>
        </Col>
      </Row>
    </div>

    <div class="page-nations-wrapper">
      <div class="page-nations">
        <Page :total="pageTotal" :current="capacityData.page" :page-size="capacityData.size" @on-change="changePage"></Page>
      </div>
    </div>

    <!-- Modal -->
    <Modal title="肺活量参考标准" v-model="standerModal" width="803">
      <div class="modalImg">
        <img src="@/assets/img/01_r11_c1.png" alt="">
      </div>
      <div slot="footer"></div>
    </Modal>

  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			columns: [
				{ type: 'selection', width: 60, align: 'center' },
				{ title: '日期', key: 'test_date', align: 'center' },
				{ title: '采集时间', key: 'test_time', align: 'center' },
				{ title: '采集方式', key: 'type', align: 'center' },
				{ title: '肺活量', key: 'value', align: 'center' },
				{
					title: '操作',
					key: 'action',
					width: 150,
					align: 'center',
					render: (h, params) => {
						if (this.power.indexOf('会员管理--删除会员信息权限') > -1) {
							return h('div', [
								h('a', {
									class: 'icon_edit',
									on: {
										click: () => {
											let id = this.capacityList[params.index].id;
											MemberService.capacityDetail({ id: JSON.stringify(id) }).then(() => {
												this.$router.push({
													name: 'CapacityEdit',
													params: { member_id: this.memberId, capId: id },
												});
											});
										},
									},
								}),
								h('a', {
									class: 'icon_del',
									on: {
										click: () => {
											this.$Modal.confirm({
												title: '提醒',
												content: '确定删除吗',
												onOk: () => {
													this.handleSingleRemove(params.index);
												},
											});
										},
									},
								}),
							]);
						} else {
							return h('div', [
								// h('a', {
								//   class: 'icon_edit',
								//   style: {
								//     marginRight: 0
								//   },
								//   on: {
								//     click: () => {
								//       let id = this.capacityList[params.index].id
								//       MemberService.capacityDetail({id: JSON.stringify(id)}).then(data => {
								//         this.$router.push({name: 'CapacityEdit', params: {member_id: this.memberId, capId: id}})
								//       })
								//     }
								//   }
								// })
							]);
						}
					},
				},
			],
			capacityData: {
				index: 1,
				start: '',
				end: '',
				page: 1,
				size: 10,
				monitor_id: '',
			},
			capacityList: [],
			pageTotal: 0,
			deleteItem: {
				ids: [],
			},
			power: [],
			standerModal: false,
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
		userInfo() {
			return this.$store.getters.userInfo;
		},
		monitorId() {
			return this.$route.params.monitorId;
		},
	},
	methods: {
		handleStander() {
			this.standerModal = true;
		},
		handleInit() {
			let arr = [];
			this.capacityData.member_id = this.memberId;
			MemberService.capacityQuery(this.capacityData).then((data) => {
				this.pageTotal = data.data_show.row_size;
				this.capacityList = data.data_show.list;
				this.deleteItem.ids = [];
				this.capacityList.forEach((item) => {
					arr = item.create_time.split(' ');
					item.test_date = arr[0];
					item.test_time = arr[1];
					item.value = `${item.value}ml`;
				});
				data.data_show.list.forEach((item) => {
					if (item.type === '0') {
						item.type = '手动';
					} else if (item.type === '1') {
						item.type = 'APP';
					} else {
						item.type = '';
					}
				});
			});
		},
		changePage(page) {
			this.capacityData.page = page;
			this.handleInit();
		},
		handleChangeDate(value) {
			this.capacityData.start = value[0];
			this.capacityData.end = value[1];
			this.handleInit();
		},
		handleSelect(selection) {
			this.deleteItem.ids = [];
			selection.forEach((item) => {
				this.deleteItem.ids.push(item.id);
			});
		},
		// 删除一个会员
		handleSingleRemove(index) {
			let req = [this.capacityList[index].id];
			MemberService.capacityDelete({ id_str: JSON.stringify(req) }).then(() => {
				this.handleInit();
				this.$Message.warning('删除成功');
			});
		},
		// 删除多个会员
		handleAllRemove() {
			if (this.deleteItem.ids.length === 0) {
				this.$Message.warning('请先勾选要删除的数据');
			} else {
				this.$Modal.confirm({
					title: '提醒',
					content: '确定删除所选数据',
					onOk: () => {
						this.handleConfirmDelete();
					},
				});
			}
		},
		// 确认删除
		handleConfirmDelete() {
			MemberService.capacityDelete({ id_str: JSON.stringify(this.deleteItem.ids) }).then(() => {
				this.handleInit();
				this.deleteItem.ids = [];
			});
		},
	},
	mounted() {
		this.power = this.userInfo.power;
		this.capacityData.monitor_id = this.monitorId;
		this.handleInit();
	},
};
</script>
<style lang="css" scoped>
.data-contain .table-box {
  margin: 0;
}
</style>
